const mpi = [
  {1: "Interior / Exterior"},
  "Head Lights, Tail Lights, Turn Signals, Brake Lights, Hazard Lights, Exterior Lamps, License Plate Lights",
  "Windshield Washer/Wiper Operation Wiper Blades",
  "Windshield Condition: Cracks / Chips / Pitting",
  "Mirrors / Glass",
  "Emergency Brake Adjustment",
  "Horn Operation",
  "Fuel Tank Cap Gasket",
  "Air Conditioning (if equipped)",
  "Clutch Operation (if equipped)",
  "Back Up Lights Left / Right",
  "Dash Warning Lights",
  "Carpet / Upholstery / Floor Mats",
  {2: "Under Vehicle"},
  "Shock Absorbers / Suspension / Struts",
  "Steering Box, Linkage, Ball Joints, Dust Covers",
  "Muffler, Exhaust Pipes/Mounts, Catalytic Converter",
  "Engine Oil and Fluid Leaks",
  "Brakes Lines, Hoses, Parking Brake Cable",
  "Drive Shaft Boots, Constant Velocity Boots, U-Joints, Transmission Linkage (if equipped)",
  "Transmission, Differential, Transfer Case, (Check Fluid Level, Fluid Condition, and Fluid Leaks)",
  "Fluid Lines and Connections, Fluid Tank Band, Fuel Tank Vapor Vent Systems Hoses",
  "Inspect Nuts and Bolts on Body and Chassis",
  {3: "Under Hood"},
  "Fluid Levels: Oil, Coolant, Battery, Power Steering, Brake Fluid, Washer, Automatic Transmission",
  "Engine Air Filter",
  "Drive Belts (condition and adjustment)",
  "Cooling System Hoses, Heater Hoses, Air Condition, Hoses and Connections",
  "Radiator Core, Air Conditioner Condenser",
  "Coolant Recovery Reservoir Fluid Level / Condition",
  "Clutch Reservoir Fluid / Condition (as equipped)",
  {4: "Battery"},
  "Battery Terminal / Cables / Mountings",
  "Condition of Battery / Cold Cranking Amps",
];

export default mpi;